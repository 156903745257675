import React, {Component} from 'react';
import Confetti from 'react-confetti'

import './App.css';

const images = [
  '/assets/images/1.jpg',
  '/assets/images/2.jpg',
  '/assets/images/3.jpg',
  '/assets/images/4.jpg',
  '/assets/images/5.jpg',
  '/assets/images/6.jpg',
  '/assets/images/7.jpg',
];

function App() {
  return (
    <div className="App">
      <Confetti numberOfPieces="30"/>
      <div className="flex">
      <Gallery list={images}/>
      <Letter/>
      </div>
    </div>
  );
}

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: 0,
    };
  }

  componentDidMount() {
    this.setState({
      interval: setInterval(() => {
        const next = this.state.index === this.props.list.length - 1 ? {index: 0} : {index: this.state.index + 1};
        this.setState(next);
      }, 4000)
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  render() {
    return (
      <div className="gallery-container">
          <img src={this.props.list[this.state.index]} alt="Us"/>
      </div>
    )
  }
}

const Letter = () =>
  <div className="letter-container">
    <h1>Happy birthday, Dad!</h1>
    <p>Thanks for being such an amazing dad, I truly appreciate all everything you've done for me! It feels crazy to finally be an adult, and I am eternally grateful to you and Mom for setting me up for success.</p>
    <p>I wish I could be there to celebrate with you, but I'm glad you're coming to visit soon. I'm super excited to show you my favorite restaurants here and for you to meet Peanut.</p>
    <p>I hope you have a fun, relaxing birthday!</p>
    <p><span role="img" aria-label="heart">&#10084;&#65039;</span> Jason</p>
  </div>;

export default App;
